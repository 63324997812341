import { ref } from 'vue'
import { CreateHint, GetSchema } from '@/api/configurationHint'
import { useShop } from '@/use/shop'
import { keys, has, set } from 'lodash'
export const useAi = () => {
  const hintLoading = ref(false)
  const { shopId } = useShop()
  const configurationHint = async (key, { prompt, origin, style }) => {
    hintLoading.value = true
    // await getSchema(key)
    const [res, err] = await CreateHint({
      shopId: shopId.value,
      key,
      prompt,
      origin: origin || undefined,
      style,
    })
    if (err) {
      window.$message.error(err)
      return
    }
    hintLoading.value = false
    return res
  }
  const setData = (target, res) => {
    const hintKeys = keys(res.hint)
    hintKeys.forEach((key) => {
      if (has(target, key)) {
        set(target, key, res.hint[key])
      }
    })
  }
  // const getSchema = async (key) => {
  //   const [res, err] = await GetSchema({
  //     shopId: shopId.value,
  //     key,
  //   })
  //   if (err) {
  //     window.$message.error(err)
  //     return
  //   }
  //   console.log('scheme:', res)
  //   return res
  // }
  return { configurationHint, hintLoading, setData }
}
