import { admin2 } from './instance'

export const CreateHint = async ({
  shopId,
  key,
  prompt,
  origin,
  style,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/configurationHint/hint`,
    data: {
      key,
      prompt,
      origin,
      style,
    },
  })
}

export const GetSchema = async ({
  shopId,
  key,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/configurationHint/schema`,
    data: {
      key,
    },
  })
}
