export const hint = {
  groupPushMessage: {
    title: '群組推播訊息',
    key: 'admin.groupPushMessage.create/messageTextContent',
  },
  groupPushMessageCorrection: {
    title: '群組推播訊息修正',
    key: 'admin.groupPushMessage.create/messageTextContent/correction',
  },
  classTicket: {
    title: '堂票',
    key: 'admin.classTicket.create',
  },
  coupon: {
    title: '使用券',
    key: 'admin.coupon.create',
  },
  couponCorrection: {
    title: '使用券修正',
    key: 'admin.coupon.create/correction',
  },
  appointmentService: {
    title: '服務項目',
    key: 'admin.appointmentService.create',
  },
  appointmentServiceCorrection: {
    title: '服務項目修正',
    key: 'admin.appointmentService.create/correction',
  },
  appointmentCategory: {
    title: '服務類別',
    key: 'admin.appointmentCategory.create',
  },
  appointmentScheduleDay: {
    title: '排班班別',
    key: 'admin.appointmentScheduleDay.create',
  },
  appointmentCreatePromptPages: {
    title: '預約確認提示設定',
    key: 'admin.appointmentCreatePromptPages.singleUpdate',
  },
  appointmentHomePage: {
    title: '預約須知設定',
    key: 'admin.appointmentHomePage.singleUpdate',
  },
  clientAnnouncement: {
    title: '佈告資訊頁',
    key: 'admin.clientAnnouncement.create',
  },
  memberCenterAd: {
    title: '會員中心佈告欄',
    key: 'admin.memberCenterAd.create',
  },
}
